import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

/* Import UI components from react-bootstrap library */
import { Container, Row, Col, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import custom .css file for the JSX template */
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    agreeTerms: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
    setFormData(newFormData);

    const allFieldsFilled = newFormData.firstName && newFormData.lastName && newFormData.email && newFormData.message && newFormData.agreeTerms;
    const emailIsValid = validateEmail(newFormData.email);
    setIsEmailValid(emailIsValid);
    setIsFormValid(allFieldsFilled && emailIsValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    const emailData = {
      from_name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      topic: formData.subject,
      message: formData.message,
      reply_to: formData.email,
    };

    emailjs.send(
      'service_eowssfo',
      'template_cf3y1ab',
      emailData,
      'tYhu1RNA5gZE5MZmi'
    )
    .then(() => {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
        agreeTerms: false,
      });
      setShowSuccessAlert(true);
      setShowErrorAlert(false);

      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);
    })
    .catch((error) => {
      console.error('FAILED...', error);
      setShowErrorAlert(true);
      setShowSuccessAlert(false);
    });
  };

  return (
    <>
    <Helmet>
      <title>
      Φόρμα επικοινωνίας - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά.
      </title>
      <meta
          name="description"
          content="Φόρμα επικοινωνίας με την πλατφόρμα του upLawyer."
        />
    </Helmet>
    <Container className="vh-100 d-flex align-items-center justify-content-center pt-5 pb-5 mt-5 mb-5 pt-md-0 pb-md-0 mt-md-0 mb-md-0">
      <Row className="d-flex justify-content-center">
        {/* Left column with title */}
        <Col lg={5} className="text-center text-lg-start">
          <h1 className="fs-1">Χρειάζεσαι βοήθεια ;</h1>
          <p className="lh-lg text-center text-md-start py-4 px-3 px-md-0 p-contact">Είμαστε εδώ για να σε βοηθήσουμε. Συμπληρώστε την φόρμα επικοινωνίας και ένα μέλος της ομάδας του <span className="navbar-brand fw-medium">upLawyer.gr</span> θα έρθει σε επικοινωνία μαζί σου.</p>
        </Col>

        {/* Right column with form */}
        <Col lg={7} className="d-flex align-items-center justify-content-center">
          <div className="w-100 mx-5">
            
            {/* Bootstrap Success Alert */}
            {showSuccessAlert && (
              <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                Το μήνυμα στάλθηκε με επιτυχία!
              </Alert>
            )}

            {/* Bootstrap Error Alert */}
            {showErrorAlert && (
              <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                Αποτυχία αποστολής μηνύματος. Παρακαλώ δοκιμάστε ξανά.
              </Alert>
            )}

            <Form onSubmit={handleSubmit}>
              {/* First Name and Last Name inline */}
              <Row>
                <Col xs={12} md={6} className="mb-3 mb-md-4">
                  <FloatingLabel controlId="formFirstName" label="Όνομα">
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="Όνομα"
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={12} md={6} className="mb-3 mb-md-4">
                  <FloatingLabel controlId="formLastName" label="Επώνυμο">
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Επώνυμο"
                      required
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              {/* Email input */}
              <FloatingLabel controlId="formEmail" label="Email" className="mb-4">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  isInvalid={!isEmailValid && formData.email !== ''}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Παρακαλώ εισάγετε μία έγκυρη διεύθυνση email.
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Subject Dropdown */}
              <Form.Group controlId="formSubject" className="mb-4">
                <FloatingLabel label="Θέμα">
                  <Form.Select
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Επιλέξτε θέμα</option>
                    <option value="Γενικές πληροφορίες">Γενικές πληροφορίες</option>
                    <option value="Τεχνικό Πρόβλημα">Τεχνικό Πρόβλημα</option>
                    <option value="Πολιτική Απορρήτου">Πολιτική Απορρήτου</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              {/* Message input */}
              <FloatingLabel controlId="formMessage" label="Μήνυμα" className="mb-4">
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Μήνυμα"
                  style={{ height: '100px', resize: 'none' }}
                  required
                />
              </FloatingLabel>

              {/* Terms and Conditions Checkbox */}
              <Form.Group controlId="formTerms" className="mt-3">
                <Form.Check
                  type="checkbox"
                  name="agreeTerms"
                  label="Αποδέχομαι όρους & πολιτική απορρήτου"
                  checked={formData.agreeTerms}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              {/* Submit Button */}
              <Button
                variant="btn btn-outline-contact w-100"
                type="submit"
                disabled={!isFormValid}
                className="mt-4"
              >
                Αποστολή
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Contact;
