import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";
import LawyerCard from "../../components/LawyerCard";
import { Container, Row, Col } from "react-bootstrap";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

import LoadingSpinner from "../../components/ui-spinner";

function SpecialtyLawyersPage() {
  const { slugname } = useParams();
  const [lawyers, setLawyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [specialtyName, setSpecialtyName] = useState("");

  useEffect(() => {
    const fetchLawyersBySpecialty = async () => {
      const query = `
      {
        lawSpecialties(where: { specialtySlug: "${slugname}" }) {
          specialtyName
        }
        lawyers(where: { lawSpecialty_some: { specialtySlug: "${slugname}" } }) {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
        }
      }
      `;
      const data = await fetchGraphQLData(query);
      if (data) {
        setSpecialtyName(data.lawSpecialties[0].specialtyName);
        setLawyers(data.lawyers);
      }
      setLoading(false);
    };

    fetchLawyersBySpecialty();
  }, [slugname]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>
          {specialtyName} - Εξειδίκευση - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά
        </title>
        <meta
          name="description"
          content={`Η λίστα των διαθέσιμων δικηγόρων του upLawyer με εξειδίκευση στο ${specialtyName}.`}
        />
      </Helmet>

      <Container className="my-3 pt-3">
        <Row>
          <h1 className="my-2">{specialtyName}</h1>
          <h5 className="mb-4 mt-3">
            Η λίστα των διαθέσιμων δικηγόρων με ειδίκευση "{specialtyName}" του{" "}
            <span className="navbar-brand fw-medium">upLawyer</span>.
          </h5>
        </Row>

        <Container className="my-3">
          <Row>
            {lawyers.length > 0 ? (
              lawyers.map((lawyer) => (
                <Col key={lawyer.id} sm={12} md={6} lg={4} className="mb-4">
                  <LawyerCard lawyer={lawyer} />
                </Col>
              ))
            ) : (
              <Col>
                <p className="text-center">
                  Δεν βρέθηκαν δικηγόροι για αυτή την ειδικότητα.
                </p>
              </Col>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default SpecialtyLawyersPage;
