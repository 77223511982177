import React, { useEffect, useState } from "react";

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";

/* Import UI components from react-bootstrap library */
import { Container, Row, Col, Pagination } from "react-bootstrap";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import UI select component from react-select library */
import Select from "react-select";

import LawyerCard from "../../components/LawyerCard";

/* Import custom .css file for the JSX template */
import "./LawyersAll.css";

const gradeMapping = {
  dikigoros_par_efetais: "Δικηγόρος παρ΄ Εφέταις",
  dikigoros_par_areio_pago: "Δικηγόρος παρ' Αρείω Πάγω",
  dikigoros_para_protodikais: "Δικηγόρος παρά Πρωτοδίκας",
};

const selectStyles = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused
      ? "rgba(236, 232, 255, 0.2)"
      : "transparent",
    color: state.isFocused ? "black" : "inherit",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: "#333333",
    borderWidth: "0.5px",
    borderRadius: "15px",
    boxShadow: state.isFocused ? "0 0 0 1px #333" : "none",
    "&:hover": {
      borderColor: "#333",
    },
    minHeight: "40px",
    padding: "3px",
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "0 10px",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: "14px",
    color: "#444444",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
    borderRadius: "25px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(100px)",
    WebkitBackdropFilter: "blur(100px)",
    fontSize: "14px",
    color: "#333",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: 10,
    backgroundColor: "transparent",
  }),
};

function LawyersPage() {
  const [lawyers, setLawyers] = useState([]);
  const [filteredLawyers, setFilteredLawyers] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [grades, setGrades] = useState([]);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 6;

  useEffect(() => {
    const fetchLawyers = async () => {
      const query = `
      {
        lawyers {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
          lawMediation
        }
      }
      `;
      const data = await fetchGraphQLData(query);
      if (data && data.lawyers) {
        setLawyers(data.lawyers);
        setFilteredLawyers(data.lawyers);

        const uniqueAssociations = [
          { value: "", label: "Όλοι οι Δικηγορικοί Σύλλογοι" },
          ...Array.from(
            new Set(
              data.lawyers
                .map((lawyer) => lawyer.lawAssociation?.associationName)
                .filter(Boolean)
            )
          ).map((association) => ({
            value: association,
            label: association,
          })),
        ];
        setAssociations(uniqueAssociations);

        const uniqueSpecialties = [
          { value: "", label: "Όλες οι Ειδικότητες" },
          ...Array.from(
            new Set(
              data.lawyers
                .flatMap((lawyer) =>
                  lawyer.lawSpecialty?.map((s) => s.specialtyName)
                )
                .filter(Boolean)
            )
          ).map((specialty) => ({
            value: specialty,
            label: specialty,
          })),
        ];
        setSpecialties(uniqueSpecialties);

        const uniqueGrades = [
          { value: "", label: "Όλοι οι βαθμοί" },
          ...Array.from(new Set(data.lawyers.map((lawyer) => lawyer.lawGrade)))
            .filter(Boolean)
            .map((grade) => ({
              value: grade,
              label: gradeMapping[grade] || grade,
            })),
        ];
        setGrades(uniqueGrades);
      }
    };

    fetchLawyers();
  }, []);

  const handleAssociationChange = (selectedOption) => {
    setSelectedAssociation(selectedOption);
    filterLawyers(selectedOption, selectedSpecialty, selectedGrade);
  };

  const handleSpecialtyChange = (selectedOption) => {
    setSelectedSpecialty(selectedOption);
    filterLawyers(selectedAssociation, selectedOption, selectedGrade);
  };

  const handleGradeChange = (selectedOption) => {
    setSelectedGrade(selectedOption);
    filterLawyers(selectedAssociation, selectedSpecialty, selectedOption);
  };

  const filterLawyers = (
    selectedAssociation,
    selectedSpecialty,
    selectedGrade
  ) => {
    let filtered = lawyers;

    if (selectedAssociation && selectedAssociation.value !== "") {
      filtered = filtered.filter(
        (lawyer) =>
          lawyer.lawAssociation?.associationName === selectedAssociation.value
      );
    }

    if (selectedSpecialty && selectedSpecialty.value !== "") {
      filtered = filtered.filter(
        (lawyer) =>
          lawyer.lawSpecialty &&
          lawyer.lawSpecialty.some(
            (specialty) => specialty.specialtyName === selectedSpecialty.value
          )
      );
    }

    if (selectedGrade && selectedGrade.value !== "") {
      filtered = filtered.filter(
        (lawyer) => lawyer.lawGrade === selectedGrade.value
      );
    }

    setFilteredLawyers(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredLawyers.length / resultsPerPage);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = filteredLawyers.slice(
    indexOfFirstResult,
    indexOfLastResult
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>Δικηγόροι - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά</title>
        <meta
          name="description"
          content="Η λίστα των διαθέσιμων δικηγόρων του upLawyer. Βρείτε εξειδικευμένους δικηγόρους για τις νομικές σας ανάγκες."
        />
      </Helmet>

      <Container className="my-2 my-md-5 lawyers-background-container">
        <Row className="justify-content-center justify-content-md-end text-center text-md-end">
          <div className="d-block d-md-none bg-white my-4">
            <h1 className="my-1">Δικηγόροι</h1>
          </div>
          <div className="d-none d-md-block">
            <h1 className="my-3">Δικηγόροι</h1>
          </div>
          <h5 className="mb-4 mt-3 d-none d-sm-block lh-base">
            Αναζήτησε εξειδικευμένο δικηγόρο για την νομική σου υπόθεση μέσα από
            το <span className="navbar-brand fw-medium">upLawyer </span>.
          </h5>
          <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-md-end">
            <span className="badge rounded-pill bg-primary px-4 py-2">
              <a className="text-white" href="/eidikotita/poiniko-dikaio">
                Ποινικό Δίκαιο
              </a>
            </span>
            <span className="badge rounded-pill bg-primary px-4 py-2">
              <a className="text-white" href="/eidikotita/emporiko-dikaio">
                Εμπορικό Δίκαιο
              </a>
            </span>
            <span className="badge rounded-pill bg-primary px-4 py-2">
              <a className="text-white" href="/eidikotita/oikogeneiako-dikaio">
                Οικογενειακό Δίκαιο
              </a>
            </span>
            <span className="badge rounded-pill bg-primary px-4 py-2">
              <a className="text-white" href="/eidikotita/klironomiko-dikaio">
                Κληρονομικό Δίκαιο
              </a>
            </span>
            <span className="badge rounded-pill bg-primary px-4 py-2">
              <a className="text-white" href="/eidikotita/dikaio-akiniton">
                Δίκαιο Ακινήτων
              </a>
            </span>
          </div>
        </Row>
      </Container>

      <div className="filter-background">
        <Container>
          <Row className="mb-0 mt-4 pb-1">
            <h5 className="filter-h5">Φίλτρα αναζήτησης</h5>
          </Row>
          <Row>
            {/* Association Filter */}
            <Col md={4} className="mt-2 pb-4">
              <Select
                styles={selectStyles}
                value={selectedAssociation}
                onChange={handleAssociationChange}
                options={associations}
                placeholder="Επιλογή δικηγορικού συλλόγου"
                isClearable
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </Col>

            {/* Specialty Filter */}
            <Col md={4} className="mt-2 pb-4">
              <Select
                styles={selectStyles}
                value={selectedSpecialty}
                onChange={handleSpecialtyChange}
                options={specialties}
                placeholder="Επιλογή ειδίκευσης δικηγόρου"
                isClearable
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </Col>

            {/* LawGrade Filter */}
            <Col md={4} className="mt-2 pb-4">
              <Select
                styles={selectStyles}
                value={selectedGrade}
                onChange={handleGradeChange}
                options={grades}
                placeholder="Επιλογή δικαιώματος παράστασης"
                isClearable
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Display filtered lawyers */}
      <Container>
        <Row>
          {currentResults.map((lawyer) => (
            <Col key={lawyer.id} sm={12} md={6} lg={4} className="mb-4">
              <LawyerCard lawyer={lawyer} />
            </Col>
          ))}
        </Row>

        {/* Pagination controls */}
        {totalPages > 1 && (
          <Row className="mt-2 mb-4">
            <Pagination className="justify-content-center">
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </Row>
        )}
      </Container>
    </>
  );
}

export default LawyersPage;
