import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaBalanceScale, FaChartLine, FaUser } from 'react-icons/fa';


function HomeTestimonals() {
  const testimonials = [
    {
      icon: <FaBalanceScale className="icon text-primary me-3" />,
      count: '450+',
      description: 'κορυφαίοι δικηγόροι',
    },
    {
      icon: <FaChartLine className="icon text-primary me-3" />,
      count: '4.295',
      description: 'αναζητήσεις δικηγόρου',
    },
    {
      icon: <FaUser className="icon text-primary me-3" />,
      count: '500+',
      description: 'μοναδικοί χρήστες',
    },
  ];

  return (
    <div className="full-width-row bg-grey mt-4 mt-md-4 pt-3 py-md-3">
      <Container fluid>
        <Row className="text-center">
          {testimonials.map((item, index) => (
            <Col
              key={index}
              xs={12}
              md={4}
              className="d-flex align-items-center justify-content-start justify-content-md-center mb-4 mb-md-0 text-start text-md-center"
            >
              {item.icon}
              <span>
                <strong>{item.count}</strong> {item.description}
              </span>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default HomeTestimonals;
