import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";

/* Import UI components from react-bootstrap library */
import { Container, Card } from "react-bootstrap";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import custom .css file for the JSX template */
import "./LawBlogPost.css";

function BlogPostPage() {
  const { blogSlug } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const fetchBlogPostDetails = async () => {
      const query = `
      {
        lawBlogPost(where: { lawPostSlug: "${blogSlug}" }) {
          lawPostTitle
          lawPostDate
          lawPostBody {
            markdown
          }
          lawyer {
            firstName
            lastName
          }
        }
      }
      `;

      const data = await fetchGraphQLData(query);

      if (data && data.lawBlogPost) {
        setBlogPost(data.lawBlogPost);
      }
    };

    fetchBlogPostDetails();
  }, [blogSlug]);

  if (!blogPost) {
    return (
      <Container className="my-4">
        <h2>Φορτώνει...</h2>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {blogPost.lawPostTitle} - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά.
        </title>
        <meta
          name="description"
          content={`Διαβάστε το άρθρο με τίτλο "${blogPost.lawPostTitle}" από ${blogPost.lawyer.firstName} ${blogPost.lawyer.lastName} στο upLawyer.gr.`}
        />
      </Helmet>

      <Container className="my-4">
        <article>
          <Card className="blog-post">
            <Card.Body>
              <header>
                <Card.Title as="h1" className="my-3 blog-post-title">
                  {blogPost.lawPostTitle}
                </Card.Title>

                <Card.Subtitle
                  as="div"
                  className="d-flex justify-content-center my-3 blog-post-meta"
                >
                  {blogPost.lawPostDate && (
                    <>
                      <div>
                        <time
                          dateTime={new Date(
                            blogPost.lawPostDate
                          ).toISOString()}
                        >
                          Δημοσίευση:{" "}
                          {new Date(blogPost.lawPostDate).toLocaleDateString()}
                        </time>
                      </div>

                      {blogPost.lawyer.firstName &&
                        blogPost.lawyer.lastName && (
                          <>
                            <span className="separator">{" | "}</span>
                            <div>
                              Αρθρογράφος: {blogPost.lawyer.firstName}{" "}
                              {blogPost.lawyer.lastName}
                            </div>
                          </>
                        )}
                    </>
                  )}
                </Card.Subtitle>
              </header>

              <ReactMarkdown className="my-4 lh-lg blog-post-body">
                {blogPost.lawPostBody.markdown}
              </ReactMarkdown>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </>
  );
}

export default BlogPostPage;
