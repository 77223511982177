import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import "./HomeHero.css";

function HomeHero() {
  const heroPRef = useRef(null);
  const heroH1Ref = useRef(null);
  const heroImageRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1068px)");

    if (mediaQuery.matches) {
      const timeline = gsap.timeline({ defaults: { ease: "power3.out" } });

      timeline.fromTo(
        heroH1Ref.current,
        { x: "-10%", opacity: 0 },
        { x: "0%", opacity: 1, duration: 1.2 }
      );

      timeline.fromTo(
        heroPRef.current,
        { opacity: 0, y: "10%" },
        { opacity: 1, y: "0%", duration: 1.5 },
        "-=0.8"
      );

      timeline.fromTo(
        heroImageRef.current,
        { scale: 0.8, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.5 },
        "-=1.2"
      );
    }

    return () => {
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <div className="hero-section mt-3">
      <div className="hero-background"></div>
      <Container fluid className="p-5 position-relative">
        <Row>
          <Col xs={12} md={5} className="d-flex flex-column justify-content-center">
            <div>
              <h1 ref={heroH1Ref} className="pb-2 text-center text-md-start">
                Ψάχνεις δικηγόρο ;
              </h1>
              <p
                ref={heroPRef}
                className="py-0 py-md-4 mt-2 text-center text-md-start hero-description"
              >
                Το <span className="navbar-brand fw-medium">upLawyer</span>{" "}
                προσφέρει μια απλή, σύγχρονη και έξυπνη εμπειρία αναζήτησης
                δικηγόρου και νομικών υπηρεσιών στην Ελλάδα.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            md={7}
            className="hero-image"
            ref={heroImageRef}
          ></Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeHero;
