import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
/* Import UI components from react-bootstrap library */
import { Container, Navbar, Nav } from "react-bootstrap";
/* Import icons from react-icons library */
import { FaRobot, FaFacebookF, FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
/* Import custom .css file for the JSX template */
import "./ui-navbar.css";

function NavBarUI() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [navbarBlur, setNavbarBlur] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setNavbarBlur(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("nav-open", isNavbarOpen);
  }, [isNavbarOpen]);

  const handleToggle = () => setIsNavbarOpen((prev) => !prev);

  const handleLinkClick = () => {
    if (isNavbarOpen) {
      setIsNavbarOpen(false);
    }
  };

  return (
    <>
      <Navbar
        expand="lg"
        className={`fixed-top ${navbarBlur || isNavbarOpen ? "navbar-blur" : "navbar-transparent"}`}
        expanded={isNavbarOpen}
      >
        <Container className="d-flex justify-content-between align-items-center">
          {/* Navbar Toggle Button */}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
            className={`order-lg-last order-1 ${
              isNavbarOpen ? "toggle-white" : ""
            }`}
          />

          {/* Navbar Brand for Desktop */}
          <Navbar.Brand as={Link} to="/" className="d-none d-lg-block">
            <strong>upLawyer</strong>
          </Navbar.Brand>

          {/* Navbar Brand for Mobile */}
          <Navbar.Brand as={Link} to="/" className={`d-lg-none mobile-brand`}>
            <strong>upLawyer</strong>
          </Navbar.Brand>

          {/* Navbar Collapse */}
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={isNavbarOpen ? "navbar-mobile-full" : ""}
          >
            <Navbar.Brand
              as={Link}
              to="/"
              className={`d-lg-none mobile-brand text-white`}
            >
              <strong>upLawyer</strong>
            </Navbar.Brand>
            {/* Main Navigation Links */}
            <Nav className="w-100 d-flex justify-content-center">
              <Nav.Link as={Link} to="/dikigoroi" className="me-3" onClick={handleLinkClick}>
                Δικηγόροι
              </Nav.Link>
              <Nav.Link as={Link} to="/eidikotites" className="me-3" onClick={handleLinkClick}>
                Εξειδικεύσεις
              </Nav.Link>
              <Nav.Link as={Link} to="/dikigorikoi-syllogoi" className="me-3" onClick={handleLinkClick}>
                Δικηγορικοί Σύλλογοι
              </Nav.Link>
              <Nav.Link as={Link} to="/blog" className="me-3" onClick={handleLinkClick}>
                Αρθρογραφία
              </Nav.Link>
            </Nav>

            {/* AI Assistant Button */}
            <Nav className="ms-lg-auto d-flex align-items-center">
              <a
                href="/ai-assistant"
                className={`btn btn-outline-uplawyer nav-btn ${
                  isNavbarOpen ? "btn-mobile-white" : ""
                }`}
                onClick={handleLinkClick}
              >
                <FaRobot className="mb-1 me-2" /> AI βοηθός
              </a>
            </Nav>

            {/* Social Media Icons */}
            <div className="social-icons-menu">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-menu"
                onClick={handleLinkClick}
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-menu"
                onClick={handleLinkClick}
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-menu"
                onClick={handleLinkClick}
              >
                <FaXTwitter />
              </a>
            </div>

            {/* Mobile-only Contact Section at Bottom Left */}
            <div className="contact-container d-lg-none">
              <p className="nav-contact-p">Contact</p>
              <a
                href="mailto:info@uplawyer.gr"
                className="nav-contact-a mt-2 mx-2 mb-1"
                onClick={handleLinkClick}
              >
                <FaEnvelope className="me-2" />
                info@uplawyer.gr
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="main-content"></div>
    </>
  );
}

export default NavBarUI;
