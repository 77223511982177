import React, { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

// Updated paths for the SVG files
import familyIcon from "../../images/family.svg";
import poleoIcon from "../../images/poleo.svg";
import realEstateIcon from "../../images/real_estate.svg";
import onlineIcon from "../../images/online.svg";
import bankIcon from "../../images/bank.svg";
import financeIcon from "../../images/finance.svg";
import natureIcon from "../../images/nature.svg";
import doctorIcon from "../../images/doctor.svg";
import poinikoIcon from "../../images/poiniko.svg";
import businessIcon from "../../images/business.svg";
import syntaxiIcon from "../../images/syntaxi.svg";
import agreementIcon from "../../images/agreement.svg";
import symvasiIcon from "../../images/symvasi.svg";
import athlitikoIcon from "../../images/athlitiko.svg";
import privacyIcon from "../../images/privacy.svg";
import carIcon from "../../images/car.svg";
import militaryIcon from "../../images/military.svg";
import energyIcon from "../../images/energy.svg";
import ergatikoIcon from "../../images/ergatiko.svg";
import dimosioypalIcon from "../../images/dimosioypal.svg";
import ektelesiIcon from "../../images/ektelesi.svg";

import "./AllSpecialties.css";

function AllSpecialties() {
  const [searchTerm, setSearchTerm] = useState("");

  const specialties = [
    {
      title: "Οικογενειακό Δίκαιο",
      img: familyIcon,
      subtitle: "Διαζύγιο, διατροφή, επιμέλεια τέκνων, σύμφωνο συμβίωσης",
      trending: true,
      path: "/eidikotita/oikogeneiako-dikaio",
    },
    {
      title: "Πολεοδομικό Δίκαιο",
      img: poleoIcon,
      subtitle: "Άδειες δόμησης, παραβιάσεις ζώνης δόμησης",
    },
    {
      title: "Δίκαιο Ακινήτων",
      img: realEstateIcon,
      subtitle: "Αγορές και πωλήσεις ακινήτων, μισθώσεις ακινήτων",
      trending: true,
      path: "/eidikotita/dikaio-akiniton",
    },
    {
      title: "Δίκαιο Διαδικτύου",
      img: onlineIcon,
      subtitle: "Προσωπικά δεδομένα, ηλεκτρονικό εμπόριο, κυβερνοέγκλημα",
      trending: true,
    },
    {
      title: "Τραπεζικό Δίκαιο",
      img: bankIcon,
      subtitle: "Διαφορές δανείων, κατασχέσεις",
      path: "/eidikotita/trapeziko-dikaio",
    },
    {
      title: "Φορολογικό Δίκαιο",
      img: financeIcon,
      subtitle: "Φορολογία ακινήτων, φόρος κληρονομιάς, προσφυγές",
      path: "/eidikotita/forologiko-dikaio",
    },
    {
      title: "Περιβαλλοντικό Δίκαιο",
      img: natureIcon,
      subtitle: "Προστασία φυσικών πόρων",
      path: "/eidikotita/perivallontiko-dikaio",
    },
    {
      title: "Ιατρικό Δίκαιο",
      img: doctorIcon,
      subtitle: "Ιατρική αμέλεια, ιατρικά λάθη, αξίωση για αποζημίωση",
    },
    {
      title: "Ποινικό Δίκαιο",
      img: poinikoIcon,
      subtitle: "Ενδοοικογενειακή βία, ρατσιστική βία, όπλα, ναρκωτικά",
      path: "/eidikotita/poiniko-dikaio",
    },
    {
      title: "Εμπορικό Δίκαιο",
      img: businessIcon,
      subtitle: "Σύσταση εταιρίας, εμπορικές πράξεις, εμπορικές συναλλαγές",
      path: "/eidikotita/emporiko-dikaio",
    },
    {
      title: "Συνταξιοδοτικό Δίκαιο",
      img: syntaxiIcon,
      subtitle: "Συνταξιοδοτικές διαφορές, ασφαλιστικά δικαιώματα",
    },
    {
      title: "Δίκαιο Συμβάσεων",
      img: agreementIcon,
      subtitle: "Εμπορικές συμβάσεις, συμβάσεις εργασίας",
    },
    {
      title: "Ιδιωτικά Συμφωνητικά",
      img: symvasiIcon,
      subtitle: "Διαχείριση προσωπικών συμβάσεων",
    },
    {
      title: "Αθλητικό Δίκαιο",
      img: athlitikoIcon,
      subtitle: "Συμβόλαια αθλητών, διαφορές ομάδων",
      path: "/eidikotita/athlitiko-dikaio",
    },
    {
      title: "GDPR, Προσωπικά Δεδομένα",
      img: privacyIcon,
      subtitle: "Προστασία δεδομένων, παραβιάσεις δεδομένων, DPO",
    },
    {
      title: "Τροχαία Ατυχήματα",
      img: carIcon,
      subtitle: "Αποζημιώσεις τροχαίων ατυχημάτων",
    },
    {
      title: "Στρατιωτικό Δίκαιο",
      img: militaryIcon,
      subtitle: "Αναβολή στράτευσης, απαλλαγή στράτευσης",
      path: "/eidikotita/stratiotiko-dikaio",
    },
    {
      title: "Δίκαιο Ενέργειας",
      img: energyIcon,
      subtitle: "Άδειες παραγωγής, ενεργειακές διαφορές",
      path: "/eidikotita/dikaio-energeias",
    },
    {
      title: "Εργατικό Δίκαιο",
      img: ergatikoIcon,
      subtitle: "Απολύσεις, εργατικές διαφορές",
    },
    {
      title: "Δημοσιουπαλληλικό Δίκαιο",
      img: dimosioypalIcon,
      subtitle: "Υποθέσεις δημόσιων υπαλλήλων, πειθαρχικά",
    },
    {
      title: "Πτωχευτικό Δίκαιο",
      img: ektelesiIcon,
      subtitle: "Πλειστηριασμοί, Υπερχρεωμένα νοικοκυριά, Νόμος Κατσέλη",
      path: "/eidikotita/ptoxeutiko-dikaio",
    },
  ];

  const normalizeText = (text) =>
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  const filteredSpecialties = specialties
    .sort((a, b) =>
      a.title.localeCompare(b.title, "el", { sensitivity: "base" })
    )
    .filter((specialty) => {
      const normalizedTitle = normalizeText(specialty.title).toLowerCase();
      const normalizedSubtitle = normalizeText(
        specialty.subtitle
      ).toLowerCase();
      const normalizedSearchTerm = normalizeText(searchTerm).toLowerCase();
      return (
        normalizedTitle.includes(normalizedSearchTerm) ||
        normalizedSubtitle.includes(normalizedSearchTerm)
      );
    });

  return (
    <>
      <Helmet>
        <title>
          Όλοι οι τομείς ειδίκευσης - upLawyer.gr | Βρες δικηγόρο εύκολα και
          απλά
        </title>
        <meta
          name="description"
          content={`Η λίστα όλων των διαθέσιμων τομέων ειδίκευσης των δικηγόρων του upLawyer.`}
        />
      </Helmet>
      <Container className="mt-4 pt-4 pb-4 mb-4">
        <h1 className="mb-4 pb-2 text-center">
          Αναζήτηση <span className="purple">τομέα ειδίκευσης</span>
        </h1>
        <Form.Group
          controlId="searchBar"
          className="mb-5 d-flex justify-content-center"
        >
          <Form.Control
            type="text"
            placeholder="Αναζήτηση κατηγορίας..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ maxWidth: "600px" }}
            className="custom-search-bar"
          />
        </Form.Group>
        {filteredSpecialties.length > 0 ? (
          Array.from({ length: Math.ceil(filteredSpecialties.length / 4) }).map(
            (_, rowIndex) => (
              <Row key={rowIndex} className={rowIndex !== 0 ? "pt-3" : ""}>
                {filteredSpecialties
                  .slice(rowIndex * 4, rowIndex * 4 + 4)
                  .map((specialty, index) => (
                    <Col key={index} xs={6} lg={3} className="mb-4">
                      <Card className="text-center p-3 specialty-card">
                        <div className="parallelogram position-relative">
                          {specialty.trending && (
                            <span className="badge bg-warning text-dark position-absolute top-0 start-0">
                              Trending
                            </span>
                          )}
                          <Link to={specialty.path}>
                            <img
                              src={specialty.img}
                              alt={specialty.title}
                              className="parallelogram-image"
                            />
                          </Link>
                        </div>
                        <Card.Title className="specialty-card-title">
                          <Link
                            to={specialty.path}
                            className="text-decoration-none text-dark"
                          >
                            {specialty.title}
                          </Link>
                        </Card.Title>
                        <Card.Subtitle className="pt-2 specialty-card-subtitle text-muted">
                          {specialty.subtitle}
                        </Card.Subtitle>
                      </Card>
                    </Col>
                  ))}
              </Row>
            )
          )
        ) : (
          <p className="text-center">Δεν βρέθηκαν αποτελέσματα.</p>
        )}
      </Container>
    </>
  );
}

export default AllSpecialties;
