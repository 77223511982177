import React, { useEffect, useRef } from 'react';

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './HomePros.css';

gsap.registerPlugin(ScrollTrigger);

const HomePros = () => {
  const prosSectionRef = useRef(null);
  const prosColumnsRef = useRef([]);

  useEffect(() => {
    const isMobile = window.innerWidth < 768;

    if (!isMobile) {

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: prosSectionRef.current,
          start: 'top 90%',
          end: 'bottom 100%',
          toggleActions: 'play none none none',
          onEnter: () => console.log('ScrollTrigger1 triggered'),
        },
      });

      timeline
        .fromTo(prosColumnsRef.current[0], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 })
        .fromTo(prosColumnsRef.current[1], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 }, '-=0.4')
        .fromTo(prosColumnsRef.current[2], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 }, '-=0.4');

      console.log("GSAP timeline1 initialized");
    }
  }, []);

  return (
    <section className="mt-5 py-5 home-pros-section text-center">
      <Container>
        <Row>
          <h1 className="mb-4 mb-md-3">Γιατί το upLawyer ;</h1>
          <p className="lh-lg">
            Το upLawyer αποτελεί μια κορυφαία επιλογή αναζήτησης δικηγόρου και νομικών υπηρεσιών στην Ελλάδα.
          </p>
        </Row>
      </Container>
      <Container>
        <Row className="my-0 my-md-5" ref={prosSectionRef}>
          <Col md={4} className="pros-column" ref={el => prosColumnsRef.current[0] = el}>
            <div>
              <h3>Τεχνητή Νοημοσύνη</h3>
              <p className="pt-2">ευφυής αναζήτηση ειδικότητας δικηγόρου</p>
            </div>
          </Col>
          <Col md={4} className="pros-column" ref={el => prosColumnsRef.current[1] = el}>
            <div>
              <h3>Επικοινωνία</h3>
              <p className="pt-2">άμεση αποστολή μηνύματος σε δικηγόρο</p>
            </div>
          </Col>
          <Col md={4} className="pros-column" ref={el => prosColumnsRef.current[2] = el}>
            <div>
              <h3>Μηδενικό Κόστος</h3>
              <p className="pt-2">δωρέαν αναζήτηση κατάλληλου δικηγόρου</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomePros;
